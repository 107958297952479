.Cell {    
    width: 30px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;    
}

.RowHighlight {    
   color: deepskyblue;  
}

.Row {        
 }

.TeamCell {
    width: 140px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;    
}

.TeamCellHighlight {
    width: 140px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;
    color: deepskyblue;
}