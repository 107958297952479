
.rmsc {
    --rmsc-main: #000 !important;
    --rmsc-hover: #555 !important;
    --rmsc-selected: #222 !important;
    --rmsc-border: #666 !important;
    --rmsc-gray: #ddd !important;
    --rmsc-bg: #000 !important;
    --rmsc-p: 4px !important;
    --rmsc-radius: 0px !important;
    --rmsc-h: 18px !important; 
    color: #fafafa !important;
  }
