
.Page {    
    padding-top: 12px;  
    padding-right: 36px;  
    padding-left: 12px;  
    background-color: black;
    color: #dddddd;
    font-size: small;
    width: 100%;
    overflow: auto;
}

.RightJustifiedCell {
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
}
.RightJustifiedCellHighlight {
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #303030;
}
.RightJustifiedCellSpecial {
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #505050;
}
.LeftJustifiedCellSpecial {
    padding-left: 2px;
    text-align: left;    
    background-color: #505050;
}

.LeftJustifiedCell {
    padding-left: 2px;
    text-align: left;
}

.HeaderCell {
    text-align: center;
    align-items: left;
    padding-left: 4px;
    padding-right: 4px;
}


.XSmallText {    
    font-size: x-small;
    color: white;    
}

.XSmallRightFloatText {    
    font-size: x-small;
    color: white;  
    float: right;
}

.XSmallTextUnderlined {    
    font-size: x-small;
    color: white;
    text-decoration: underline;
}

.Dropdown {
    background-color: white;
    color: black;
}

.DropdownLabel {
    font-size: smaller;
    margin-right: 10px;    
}

.Chart {    
    max-width: 750px;    
    margin-right: 20px;
    background-color: black; 
}

.TableRow {
    background-color: #1a1a1a;
    padding-top: 20px;
    margin-bottom: 12px;
    white-space: nowrap;
}

.Table{
    max-width: 750px;
    width: 100%;
}

.Home_HeaderTable{
    max-width: 750px;
    
}

.Table_Stats{    
    max-width: 750px;
    width: 97%;
}

.Heading {
    text-align: center;
    max-width: 750px;
    color: white;
}

.SmallHeading {
    font-size: xx-small;    
}

.Loading {
    background-color: black;
    color: #eeeeee;
}

.LineCell {
    text-align: center;
}

.TabControl{
    margin-top: 16px;
}

html,body{
    overflow-x:scroll;
}

.DivLang {
    float: right;
}

.DivHomeHeader {
    width: 100%;
    max-width: 750px; 
}

.NoWrap {
    width: 100%;
    vertical-align: bottom;
    white-space: nowrap;
}

.TabContent {
    margin-bottom: 64px;
}

.Center {        
    margin-top: 24px;
    text-align: center;
  }
  