.Cell {    
    width: 30px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;    
}

.Table_Standings{    
    max-width: 500px;    
    width: 100%;
    margin-top: -12px;
}

.TeamCell {
    width: 140px;   
    padding-left: 2px;    
}
