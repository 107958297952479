.Title {    
    font-size:small;
    font-weight: bold;
    vertical-align: middle;
    margin-top: 8px;
    margin-bottom: 12px;        
}

.TableContainer {
    margin-bottom: 32px;
}

.Table_Game{
    margin-bottom: 0px;
    width: 100%;
    max-width: 500px;
    border-collapse: collapse; 
    border-spacing: 0; 
}

.Table_PregamePlayers{    
    margin-bottom: 16px;
    width: 100%;
    max-width: 500px;    
    border-collapse: collapse; 
    border-spacing: 0; 
}
.Table_PregamePlayersEmpty{    
    margin-bottom: 4px;
    width: 100%;
    max-width: 500px;    
    border-collapse: collapse; 
    border-spacing: 0; 
}

.Table_Goal{
    background-color: #131313;   
    width: 100%;     
    max-width: 500px;
    margin-bottom: 4px;
}
.ScorerTableRow {
    background-color: #131313;
    padding-top: 20px;
    margin-bottom: 12px;
    white-space: nowrap;
}

.Table_Scorers{
    width: 100%;     
    max-width: 500px;    
}
.Table_Goalies{    
    max-width: 500px;    
    background-color: #131313;    
    margin-bottom: 0px;
    white-space: nowrap;
}

.Table_Empty{
    margin-bottom: 12px;
}

.Table_Assists{
    margin-bottom: 8px;
}

.NoGames {    
    margin-left: 2px;
}

.GameHeaderTeamCell {     
    text-align: left;
    width: 110px;    
}

.PregamePlayerCell {        
    color: deepskyblue;   
    opacity: 1;
    text-align: left;    
    width: 110px;
}

.PregamePlayerCellOpaque {    
    color: deepskyblue;   
    opacity: 0.5;
    text-align: left;
    width: 110px;
}

.GameHeaderDashCell {    
    font-weight: bold;
    text-align: left;  
    padding-right: 8px;
    width: 1%;    
}
.GameHeaderScoreCell {        
    font-weight: bold;    
    text-align: right;  
    padding-right: 4px; 
    width: 15%;        
}
.GameHeaderTimeCell {      
    width: 8%;     
    text-align: left;
    padding-left: 0px;
    vertical-align: middle;
    font-size: smaller;
}
.GameHeaderEndStatusCell {        
    width: 8%;             
    text-align: left;    
}
.GameHeaderStatusCell {    
    width: 23%;          
    padding-right: 8px;
    text-align: right;    
}

.GameVideoCell {    
    padding-left: 4px;
    width:0px;
}

.Player_Normal{        
    text-align: left;
    width:max-content;    
}
.Player_NormalRightJustified{        
    text-align: right;
    width:max-content;    
}

.Player_Highlight{    
    color:deepskyblue;
}
.Player_HighlightRightJustified{    
    text-align: right;
    color:deepskyblue;
}
.InvisibleText{        
    color:#131313;
    background-color: #131313;
}

.GoalType {
    text-align: left;
    font-size:x-small;
    color: #cccccc;
    width: 100%;    
}

.Assist_Main {
    display: flex;
}
.Assist_One_Highlight {    
    color:deepskyblue;
    display: flex;
}
.Assist_Two {
    margin-left: 4px;
}
.Assist_Two_Highlight {    
    color:deepskyblue;
    margin-left: 4px;
}

html,body{
    overflow-x:scroll;    
}

.Td_Fit {
    width: 60%;     
    text-align: right;    
    padding-right: 8px;

}
.Table_Fill {
    width: 100%;
    max-width: 500px;
}
.Td_Fill {
    width: 40%; 
}

.Div_FullWidth {
    background-color: #131313;
    width: 100%;
}

.Div_TopMargin {
    margin-top: 10px;
}

.Div_CheckboxLabel{
    padding-right: 8px;
    vertical-align:auto;
}

.ScoreFilterText {    
    font-size: smaller;
    color: white;    
}

.ScoreFilterTextSelected {    
    font-size: smaller;
    color: white; 
    text-decoration: underline;   
}

.DivLang {
    vertical-align: middle;
}

.PlayoffGameHeader {    
    text-align: left;
    font-size:x-small;
    color: #aaaaaa;     
}