.LeftJustifiedPlayerCell
{
    padding-left: 2px;
    padding-right: 8px;
    text-align: left;
}

html,body{
    overflow-x:scroll;    
}

.Stats__headerTable{
    max-width: 750px;
    width: 100%;
}

html,body{
    overflow-x:scroll;    
}

.NtTypeText {    
    font-size: smaller;
    color: white;    
}

.NtTypeTextSelected {    
    font-size: smaller;
    color: white; 
    text-decoration: underline;   
}
