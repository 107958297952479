html,body{
    overflow-x:scroll;    
}

.StatTypeText {    
    font-size: smaller;
    color: white;    
}

.StatTypeTextSelected {    
    font-size: smaller;
    color: white; 
    text-decoration: underline;   
}
.Table_Standings{    
    max-width: 750px;
    width: 100%;
}

.TeamCell {
    width: 140px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;    
}

.TeamCellHighlight {
    width: 140px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    padding-left: 2px;
    text-align: left;
    color: deepskyblue;
}
