.LatestGameDay__tableRow {
    background-color: #1a1a1a;
    padding-top: 20px;
    margin-bottom: 12px;
    white-space: nowrap;
}

.LatestGameDay__table{
    max-width: 600px;
    width: 100%;
}

html,body{
    overflow-x:scroll;    
}
