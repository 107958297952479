.Stats__headerTable{
    max-width: 750px;
    width: 100%;
}

html,body{
    overflow-x:scroll;    
}

.StatTypeText {    
    font-size: smaller;
    color: white;    
}

.StatTypeTextSelected {    
    font-size: smaller;
    color: white; 
    text-decoration: underline;   
}

.TableContainer {
    width: 100%;
    max-width: 500px;
    margin-bottom: 32px;
}